@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800;900;1000&display=swap');

* {
    --color1: #242424;
    --color2: #f0f4f7;
    --color3: #3c3c3c;
    --color4: rgb(89, 172, 140);
    --white: #fff;
    box-sizing: border-box;
    outline: 0;
    outline-style: none;
}

a {
    text-decoration: none;
    color: var(--color4);
    font-weight: 300;
}

html {
    scroll-behavior: smooth;
}

textarea {
    resize: none;
}

body {
    margin: 0;
    width: 100%;
    height: 100vh;
    font-family: 'Mulish', cursive;
    background-color: var(--white);
}

.absolute {
    position: absolute;
}

.alignLeft {
    text-align: left;
}

.bannerTitle {
    min-width: 100%;
    max-height: 120px;
    min-height: 120px;
    padding: 1rem;
}

.bgColor1 {
    background-color: var(--color1);
}

.bgColor2 {
    background-color: var(--color2) !important;
}

.bgColor3 {
    background-color: var(--color3);
}

.bgColor4 {
    background-color: var(--color4);
}

.bgColorWhite {
    background-color: #fff;
}

.box {
    max-width: 550px;
    width: 100%;
    min-width: 360px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}

.borderBottom {
    border: 0 0 0 0;
    border-bottom: 1px solid gray;
    padding-bottom: 0.5rem;
}

.brandImg {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.btn,
.btn2 {
    font-family: 'Encode Sans', sans-serif;
    font-size: 1rem;
    background-color: transparent;
    border: none;
    border-radius: 5px;
    padding: 0.75rem 1rem;
    background-color: #ffc727;
    margin: auto;
    margin-bottom: 1rem;
    cursor: pointer;
}

.btn2 {
    background-color: var(--color1);
    color: white;
}

.card {
    position: relative;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.152);
    background-color: var(--white);
    width: calc(100% - 4rem);
    max-width: 550px;
    padding: 3rem;
    margin: 1rem auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.card:before {
    content: '';
    background-color: var(--color2);
    position: absolute;
    width: 95%;
    height: 20px;
    left: 50%;
    transform: translateX(-50%);
    top: 100%;
    border-radius: 50% 50% 0 0;
    z-index: 31;
}

.card:after {
    content: '';
    width: 90%;
    position: absolute;
    height: 10px;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--color3);
    z-index: 23;
    position: absolute;
}

.center {
    width: 100%;
    text-align: center;
}

.color1 {
    color: var(--color1);
}

.color2 {
    color: var(--color4);
}

.circle {
    border-radius: 50%;
}

.contactIcon {
    position: fixed;
    bottom: 0.5rem;
    right: 1rem;
    padding: 1rem;
    animation-name: animation;
    animation-duration: 3s;
    animation-iteration-count: infinite;
}

.cursor {
    cursor: pointer;
}

@keyframes animation {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}

.flexWrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
}

.fontWhite {
    color: var(--white);
}

.fontSmall {
    font-weight: 400;
}

.footerBox {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
}

.fullPadding {
    padding-top: 4rem;
    padding-bottom: 4rem;
}

.fullPaddingTop {
    padding-top: 4rem;
}

.fullPaddingBottom {
    padding-bottom: 4rem;
}

.headerBox {
    width: 100%;
    height: 150px;
    background-color: var(--color1);
    padding: 1rem;

}

.galery {
    height: 250px;
    width: 50%;
    min-width: 350px;
    background-repeat: no-repeat;
    background-position: center;
    object-fit: cover;
}

.justifyTop {
    align-items: flex-start;
}


.hidden {
    display: none;
}

.input {
    border: none;
    padding: 1rem;
    border: 1px solid lightgray;
    border-radius: 5px;
    position: relative;
    /* min-width: calc(335px - 4rem); */
    width: 100%;
    margin-bottom: 1rem;
}

.inputLabel {
    position: absolute;
    top: -10px;
    left: 7.5%;
    z-index: 10;
    background-color: white;
    color: gray;
    padding: 0 0.5rem;

}

.leftBox,
.rightBox {
    width: 50%;
    min-width: 330px;
    text-align: center;
    margin: auto;
}

.leftBox {
    padding-top: 0;
}

.marginAuto {
    margin: auto;
}

.marginTop {
    margin-top: 1rem;
}

.marginBottom {
    margin-bottom: 1rem;
}

.marginLeft {
    margin-left: 1rem;
}

.map {
    width: 100%;
    height: 275px;
    object-fit: cover;

}

.maxWidth {
    max-width: 1400px;
}

.mySlides {
    min-width: 200px;
    height: 150px;
    margin: 0 1rem;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50%;
}

.noMargin {
    margin: 0;
}

.noPadding {
    padding: 0;
}

.paddingBottom {
    padding-bottom: 1rem;
}

.padding {
    padding: 1rem;
}

.paddingTop {
    padding-Top: 1rem;
}

.paddingV {
    padding-Top: 1rem;
    padding-bottom: 1rem;
}

.paddingH {
    padding-left: 1rem;
    padding-right: 1rem;
}

#pinIcon {
    font-size: 3rem;
    left: 35%;
    top: 10%;
}

.relative {
    position: relative;
}

.slideshow-container {
    width: 100%;
    height: 100%;
    position: relative;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.sliderIconLeft,
.sliderIconRight {
    font-size: 3rem;
    position: absolute;
    top: calc(50% - 1rem);
    left: 1rem;
    transform: translateY(50%);
    color: rgba(113, 113, 113, 0.246);
    cursor: pointer;
}

.sliderIconRight {
    left: auto;
    right: 1rem;
}

.spriteImg {
    background-image: url("../imgs/sprite.png");
    width: 100%;
    height: 150px;
    background-position-y: 50%;
    overflow: hidden;
}

.socialIcons {
    font-size: 2rem;
    margin-right: 1rem;
    margin-left: 1rem;
    color: #fff;
    cursor: pointer;
}

.width90 {
    width: 90%;
}

.width30 {
    width: 30%;
}